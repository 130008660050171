<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveCurrency">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col class="py-0" cols="12">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" sm="10">
                                    <ValidationProvider ref="currency" rules="required|min:2|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('currency_name')}}</div>
                                        <v-text-field v-model="currency" type="text"
                                                      :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('currency_name')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="2">
                                    <ValidationProvider ref="letter_code" rules="required|min:3|max:3"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('currency_letter_code')}}</div>
                                        <v-text-field v-model="letter_code" type="text"
                                                      :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('currency_letter_code')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="4">
                                    <ValidationProvider ref="name_short" rules="required|min:1|max:3"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('currency_name_short')}}</div>
                                        <v-text-field v-model="name_short" type="text"
                                                      :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('currency_name_short')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="4">
                                    <ValidationProvider ref="character_code" rules="required|min:1|max:3"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('currency_character_code')}}</div>
                                        <v-text-field v-model="character_code" type="text"
                                                      :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('currency_character_code')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="4">
                                    <ValidationProvider ref="numeric_code" rules="required|numeric|min_value:100|max_value:999"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('currency_numeric_code')}}</div>
                                        <v-text-field v-model="numeric_code" type="number"
                                                      :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('currency_numeric_code')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="exchange_rate" rules="required|min_value:0.00000001|max_value:99999999"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('currency_exchange_rate')}}</div>
                                        <v-text-field v-model="exchange_rate" type="number"
                                                      :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('currency_exchange_rate')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 py-7">
                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";

export default {
    name: 'CurrencyForm',
    components: {
        ValidationProvider,
        ValidationObserver
    },
    inject: ['forceRerender'],
    data() {
        return {
            dialogForm: true,
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            currency: null,
            name_short: null,
            letter_code: null,
            exchange_rate: 1,
            numeric_code: null,
            character_code: null,
            exist_translations: {},
            all_translations: true,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.checkCreate()
    },
    methods: {
        checkCreate() {
            if (this.$route.name === "currency.create") {
                this.heading = this.$t('currency_creation')
            } else {
                this.heading = this.$t('currency_editing')
                if (this.$route.params.id) {
                    this.getCurrency()
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        closeDialogAdd(){
            this.$router.back()
        },
        async getCurrency() {
            this.loading = true
            let params = {};
            if (this.language) {
                params.language = this.language;
            }
            await this.$http
                .get(`admin/currency/${this.$route.params.id}`, {params: params})
                .then(res => {
                    this.currency = res.body.data.name
                    this.name_short = res.body.data.name_short
                    this.letter_code = res.body.data.letter_code
                    this.exchange_rate = res.body.data.exchange_rate
                    this.numeric_code = res.body.data.numeric_code
                    this.character_code = res.body.data.character_code
                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_currency'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async saveCurrency() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.currency) {
                formData.append('currency', this.currency)
            }
            if (this.name_short) {
                formData.append('name_short', this.name_short)
            }
            if (this.exchange_rate > 0) {
                formData.append('exchange_rate', this.exchange_rate)
            }
            if (this.numeric_code) {
                formData.append('numeric_code', this.numeric_code)
            }
            if (this.letter_code) {
                formData.append('letter_code', this.letter_code)
            }
            if (this.character_code) {
                formData.append('character_code', this.character_code)
            }
            if (this.$route.params.id) {
                // Save
                await this.$http
                    .put(`admin/currency/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('currency_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('currency_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/currency', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('currency_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                            //this.forceRerender()
                        }
                        this.$router.push({
                            name: 'currency'
                        })
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('currency_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
